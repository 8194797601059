import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { ListItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import ValidatePromoDialog from "./ValidatePromoDialog";
import PriceWrapper from "../ui-core/PriceWrapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import { muted2 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({ root: { border: `1px dashed ${muted2}` } }),
  mapPropsStream(propsStream => {
    const {
      handler: setShowDialog,
      stream: showDialogStream,
    } = createEventHandler();

    return propsStream.combineLatest(
      showDialogStream.startWith(false),
      (props, showDialog) => ({
        ...props,
        showDialog,
        setShowDialog,
      }),
    );
  }),
);

const PromoFieldComponent = enhancer(
  ({
    showDialog,
    setShowDialog,
    input,
    classes,
    validatePromo,
    currencyCode,
    i18n,
  }) => (
    <div className={classes.root}>
      <ValidatePromoDialog
        open={showDialog}
        onRequestClose={() => setShowDialog(false)}
        onSubmit={values =>
          validatePromo(values.promo).catch(ResponseError.throw)
        }
        onSubmitSuccess={response => {
          setShowDialog(false);
          input.onChange(response.data);
        }}
      />

      <ListItem
        onClick={() => setShowDialog(true)}
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          cursor: "pointer",
        }}
      >
        <ListItemText
          primary={
            input.value
              ? i18n.get("apply_anothe_promo_code", "Apply another promo Code")
              : i18n.get("apply_promo_code", "Apply Promo Code")
          }
        />
      </ListItem>

      {Boolean(input.value) && (
        <div>
          <ListItem disabled={true}>
            <ListItemText
              primary={input.value.title}
              secondary={input.value.subtitle}
            />
          </ListItem>
          <ListItem disabled={true}>
            <ListItemText
              primary={i18n.get("discount", "Discount")}
              secondary={
                <PriceWrapper
                  code={currencyCode}
                  price={input.value.discount}
                />
              }
            />
          </ListItem>
        </div>
      )}
    </div>
  ),
);

FormPromoField.propTypes = {
  name: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  validatePromo: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

export default function FormPromoField(props) {
  return <Field {...props} component={PromoFieldComponent} />;
}
