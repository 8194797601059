import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Fields } from "redux-form";
import TimeSlotList from "./TimeSlotList";
import DateSwitcher from "../form/DateSwitcher";

TimeSlotComponent.propTypes = {
  maxDate: PropTypes.any,
  minDate: PropTypes.any,

  onRequestClose: PropTypes.func,

  timeSlotField: PropTypes.string.isRequired,
  timeSlotDateField: PropTypes.string.isRequired,

  timeSlotsFetching: PropTypes.bool.isRequired,
  timeSlots: PropTypes.instanceOf(List).isRequired,
};

TimeSlotComponent.defaultProps = {
  onRequestClose: fp.noop,
};

function TimeSlotComponent(props) {
  const timeSlot = props[props.timeSlotField];
  const timeSlotDate = props[props.timeSlotDateField];

  return (
    <div>
      <DateSwitcher
        minDate={props.minDate}
        maxDate={props.maxDate}
        {...timeSlotDate.input}
      />

      <TimeSlotList
        date={timeSlotDate.input.value}
        timeSlots={props.timeSlots}
        timeSlotsFetching={props.timeSlotsFetching}
        onChange={fp.flow(timeSlot.input.onChange, props.onRequestClose)}
      />
    </div>
  );
}

FormTimeSlot.propTypes = {
  maxDate: PropTypes.any,
  minDate: PropTypes.any,

  onRequestClose: PropTypes.func,

  timeSlotField: PropTypes.string.isRequired,
  timeSlotDateField: PropTypes.string.isRequired,

  timeSlotsFetching: PropTypes.bool.isRequired,
  timeSlots: PropTypes.instanceOf(List).isRequired,
};

export default function FormTimeSlot(props) {
  return (
    <Fields
      {...props}
      names={[props.timeSlotField, props.timeSlotDateField]}
      component={TimeSlotComponent}
    />
  );
}
