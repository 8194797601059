import { Map } from "immutable";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PROMO_VALIDATE_URL = `${API_ROOT_URL}/customer/promo`;

export const validatePromo = (request: Map) =>
  api.post(PROMO_VALIDATE_URL, {
    body: request.toJS(),
    headers: {
      "x-app-type": "customer-web",
    },
  });
