import React from "react";
import _ from "lodash";
import {
  addDays,
  isAfter,
  isToday,
  subDays,
  addYears,
  endOfDay,
  isBefore,
  subYears,
  isTomorrow,
  startOfDay,
  isYesterday,
} from "date-fns";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import { safeParseDate } from "../../helpers/DateUtils";
import { DATE_SHORT_FORMAT, formatDate } from "../../helpers/FormatUtils";
import { getIsRTL, getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    container: { margin: "6px 0" },
    dateContainer: { position: "relative" },
    dateTitle: {
      position: "absolute",
      fontWeight: "bold",
      top: "16px",
      width: "100%",
      textAlign: "center",
    },
    datePicker: { opacity: 0, "& > div": { cursor: "pointer" } },
  }),
  connect(state => ({
    i18n: getMessages(state),
    isRTL: getIsRTL(state),
  })),
);

const formatDateTitle = (date, i18n) => {
  if (isToday(date)) {
    return _.toUpper(i18n.get("today", "TODAY"));
  } else if (isYesterday(date)) {
    return _.toUpper(i18n.get("yesterday", "YESTERDAY"));
  } else if (isTomorrow(date)) {
    return _.toUpper(i18n.get("tomorrow", "TOMORROW"));
  }

  return formatDate(date);
};

DateSwitcher.propTypes = {
  classes: PropTypes.object,
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
  isRTL: PropTypes.bool,
  formatValue: PropTypes.string,
};

DateSwitcher.defaultProps = {
  maxDate: addYears(new Date(), 100),
  minDate: subYears(new Date(), 100),
  formatValue: DATE_SHORT_FORMAT,
};

function DateSwitcher(props) {
  const { classes, i18n, isRTL } = props;

  const date = safeParseDate(props.value);
  const minDate = endOfDay(safeParseDate(props.minDate));
  const maxDate = startOfDay(safeParseDate(props.maxDate));

  return (
    <FlexBox
      gutter={8}
      flex={true}
      justify="space-between"
      className={classes.container}
    >
      <FlexBox>
        <IconButton
          disabled={!date || isBefore(date, minDate)}
          onClick={() => props.onChange(subDays(date, 1))}
        >
          {isRTL ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      </FlexBox>
      <FlexBox
        className={classes.dateContainer}
        justify="center"
        align="center"
      >
        <div className={classes.dateTitle}>
          {date
            ? formatDateTitle(date, i18n)
            : i18n.get("select_date", "SELECT DATE")}
        </div>
        <DatePicker
          minDate={props.minDate}
          maxDate={props.maxDate}
          onChange={d => {
            props.onChange(d || new Date());
          }}
          name={props.name}
          value={date}
          label={i18n.get("select_date", "Select Date")}
          className={classes.datePicker}
          format={props.formatValue}
        />
      </FlexBox>
      <FlexBox>
        <IconButton
          disabled={!date || isAfter(date, maxDate)}
          onClick={() => props.onChange(addDays(date, 1))}
        >
          {isRTL ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DateSwitcher);
