import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { primary1 } from "../../../../shared/theme/main-theme";
import Background from "../../../assets/customer_order_creation_form_bg.png";

const enhancer = compose(
  useSheet({
    root: {
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      top: "64px",
      display: "flex",
      position: "fixed",
    },
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      position: "absolute",
      backgroundColor: `rgba(${primary1}, 0.6)`,
      backgroundImage: `url(${Background})`,
      backgroundPosition: "top center",
      backgroundSize: "cover",
    },
  }),
);

OrderCreateWizardOverlay.propTypes = {
  classes: PropTypes.object,
};

function OrderCreateWizardOverlay({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
    </div>
  );
}

export default enhancer(OrderCreateWizardOverlay);
