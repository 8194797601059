import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { FitBounds } from "react-google-map-components";
import BrandMarker from "../maps/BrandMarker";
import PolylineWrapper from "../maps/PolylineWrapper";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { ON_HIS_WAY, OUT_FOR_DELIVERY } from "../../constants/OrderStatusCodes";
import BrandMarkerCar from "../../components/maps/BrandMarkerCar";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import { connect } from "react-redux";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import LeafletBrandMarkerCar from "../maps/osm/BrandMarkerCar";
import {
  Route,
  FitBounds as LeafletFitBounds,
} from "react-leflet-map-components";
import MarkerAccent from "../maps/assets/marker-accent.png";
import MarkerPrimary from "../maps/assets/marker-primary.png";
import CarGreen from "../maps/assets/car_green.png";

const enhancer = compose(
  connect(state => ({
    mapProvider: getMapProvider(state),
  })),
  useSheet({ root: { margin: "0 6px 6px", height: "320px" } }),
  mapPropsStream(propsStream => {
    const driverPositionStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .switchMap(props => {
        const { order } = props;

        if (!order.hasIn(["driver", "id"])) {
          return Observable.of(Immutable.Map());
        }

        return props
          .getDriverLocation(order.getIn(["driver", "id"]))
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map(x => x.get("payload"))
          .repeatWhen(stream =>
            stream.delay(
              order.get("status") === ON_HIS_WAY ||
                order.get("status") === OUT_FOR_DELIVERY
                ? 5 * 1000
                : 5 * 60 * 1000,
            ),
          );
      })
      .startWith(Immutable.Map())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(driverPositionStream, (props, driverPosition) => ({
        ...props,
        driverPosition,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderDetailsDialogMap.propTypes = {
  classes: PropTypes.object,
  driverPosition: PropTypes.instanceOf(Immutable.Map),

  getDriverLocation: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Immutable.Map).isRequired,
  mapProvider: PropTypes.string,
};

function OrderDetailsDialogMap(props) {
  const { classes } = props;

  const pickupPosition = {
    lat: props.order.getIn(["locations", 0, "lat"], 0),
    lng: props.order.getIn(["locations", 0, "lon"], 0),
  };

  const dropoffPosition = {
    lat: props.order.getIn(["locations", 1, "lat"], 0),
    lng: props.order.getIn(["locations", 1, "lon"], 0),
  };

  const driverPosition = {
    lat: props.driverPosition.get("lat"),
    lng: props.driverPosition.get("lon"),
  };

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  const driverPath = isValidCoordinates(driverPosition)
    ? [
        {
          lat: driverPosition.lat,
          lng: driverPosition.lng,
          icon: {
            url: CarGreen,
            options: {
              anchor: { x: 16, y: 16 },
              size: { width: 32, height: 32 },
            },
          },
        },
      ]
    : [];

  return (
    <Paper className={classes.root}>
      {isGoogleMapProvider ? (
        <GoogleMapWrapper>
          <BrandMarker position={pickupPosition} />
          <BrandMarker accent={false} position={dropoffPosition} />

          <PolylineWrapper
            origin={pickupPosition}
            destination={dropoffPosition}
          />

          {!isValidCoordinates(driverPosition) ? (
            <FitBounds latLngBounds={[pickupPosition, dropoffPosition]} />
          ) : (
            <div>
              <BrandMarkerCar position={driverPosition} />

              <FitBounds
                latLngBounds={[pickupPosition, dropoffPosition, driverPosition]}
              />
            </div>
          )}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper>
          <Route
            strokeColor="#FF0000"
            strokeWeight={3}
            strokeOpacity={0.8}
            origin={{
              lat: pickupPosition.lat,
              lng: pickupPosition.lng,
              icon: {
                url: MarkerPrimary,
                options: {
                  anchor: { x: 16, y: 16 },
                  size: { width: 32, height: 32 },
                },
              },
            }}
            destination={{
              lat: dropoffPosition.lat,
              lng: dropoffPosition.lng,
              icon: {
                url: MarkerAccent,
                options: {
                  anchor: { x: 16, y: 16 },
                  size: { width: 32, height: 32 },
                },
              },
            }}
            path={driverPath}
          />
          <LeafletBrandMarker position={pickupPosition} />
          <LeafletBrandMarker accent={false} position={dropoffPosition} />
          {!isValidCoordinates(driverPosition) ? (
            <LeafletFitBounds
              latLngBounds={[pickupPosition, dropoffPosition]}
            />
          ) : (
            <div>
              <LeafletBrandMarkerCar position={driverPosition} />

              <LeafletFitBounds
                latLngBounds={[pickupPosition, dropoffPosition, driverPosition]}
              />
            </div>
          )}
        </LeafletMapWrapper>
      )}
    </Paper>
  );
}

export default enhancer(OrderDetailsDialogMap);
