import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TEXT_TYPE } from "./Text";
import Distance from "./Distance";
import { getMarketplaceDefaultUnitOfDistance } from "../../reducers/MarketplaceReducer";

const enhancer = compose(
  connect(state => ({
    unitOfDistance: getMarketplaceDefaultUnitOfDistance(state),
  })),
);

DistanceWrapper.propTypes = {
  className: PropTypes.string,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  type: TEXT_TYPE,
  fallbackValue: PropTypes.node,
  fallbackType: TEXT_TYPE,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitOfDistance: PropTypes.string,
};

function DistanceWrapper({ distance, unitOfDistance, ...props }) {
  return (
    <Distance {...props} distance={distance} unitOfDistance={unitOfDistance} />
  );
}

export default enhancer(DistanceWrapper);
