import Immutable, { Map } from "immutable";
import { combineReducers } from "redux-immutable";
import { getMarketplace } from "./MarketplaceReducer";
import { injectReducer } from "../../shared/helpers/ReducerContext";
import { getAppName } from "../../shared/reducers/AppReducer";
import {
  SUBWAY_ID,
  UPLIFT_ID,
  WING_AE_ID,
  WING_SA_ID,
  FOOD_KART_ID,
  SWISH_NOW_ID,
  WING_TEST_APP,
  DELIVERYUAE_ID,
  FOODKART_UAE_ID,
} from "../../server/constants/MarketplaceId";

const selector = injectReducer(
  "messages",
  combineReducers({
    [WING_AE_ID]: () =>
      Immutable.fromJS({
        customer: {
          orderCreatePickUpLocation: "Who are we picking it from?",
          orderCreateDropOffLocation: "To whom are we shipping it?",
        },
      }),
    [WING_SA_ID]: () =>
      Immutable.fromJS({
        customer: {
          orderCreatePickUpLocation: "Who are we picking it from?",
          orderCreateDropOffLocation: "To whom are we shipping it?",
        },
      }),
    [FOOD_KART_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Food Delivery Orders",
        },
        admin: {
          sameDay: "Express Delivery",
        },
      }),
    [FOODKART_UAE_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Food Delivery Orders",
        },
        admin: {
          sameDay: "Express Delivery",
        },
      }),
    [DELIVERYUAE_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Food Delivery Orders",
        },
        admin: {
          sameDay: "Express Delivery",
        },
      }),
    [UPLIFT_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Delivery Orders",
        },
        admin: {
          urgent: "Express Delivery",
          sameDay: "Scheduled Delivery",
        },
      }),
    [SUBWAY_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Delivery Orders",
        },
        admin: {
          urgent: "Express Delivery",
        },
      }),
    [SWISH_NOW_ID]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Delivery Orders",
        },
        admin: {
          urgent: "Express Delivery",
          sameDay: "Scheduled Delivery",
        },
      }),
    [WING_TEST_APP]: () =>
      Immutable.fromJS({
        customer: {
          domesticOrders: "Delivery Orders",
        },
        admin: {
          sameDay: "Express Delivery",
        },
      }),
  }),
);

export function getMessage(state, text, defaultText) {
  const marketplace = getMarketplace(state);
  const app = getAppName(state);
  const marketplaceId = marketplace && marketplace.get("marketplace_id");

  return selector(state).getIn([String(marketplaceId), app, text], defaultText);
}

export function getMessages(state) {
  const marketplace = getMarketplace(state);
  const app = getAppName(state);
  const marketplaceId = marketplace && marketplace.get("marketplace_id");

  return selector(state).getIn([String(marketplaceId), app], Map());
}
