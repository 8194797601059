import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { width: "320px" } }),
  reduxForm({
    form: "ValidatePromoDialog",
    validate: (values, props) => ({
      promo:
        !values.promo &&
        props.getLocalisationMessage("enter_promo_code", "Enter Promo Code"),
    }),
  }),
);

ValidatePromoDialog.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ValidatePromoDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      contentClassName={props.classes.content}
      PaperProps={{
        style: {
          width: "320px",
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />

      <DialogContent>
        <form>
          <FormTextField
            name="promo"
            fullWidth={true}
            label={getLocalisationMessage("promo_code", "Promo Code")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ValidatePromoDialog);
