import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import {
  CARD,
  CASH,
  CREDIT_BALANCE,
  COMBINED,
  NON_PAYMENT,
  ADVANCE_BOOK,
} from "../../constants/OrderPaymentTypes";
import cash from "./assets/cash.svg";
import creditCard from "./assets/card.svg";
import creditBalance from "./assets/credit.svg";
import combined from "./assets/combined.svg";
import advBook from "./assets/advbook.svg";
import nonPayment from "./assets/bezoplati.svg";

const icons = {
  [CASH]: cash,
  [CREDIT_BALANCE]: creditBalance,
  [CARD]: creditCard,
  [COMBINED]: combined,
  [ADVANCE_BOOK]: advBook,
  [NON_PAYMENT]: nonPayment,
};

PaymentTypeAvatar.propTypes = {
  paymentType: PropTypes.oneOf([
    CASH,
    NON_PAYMENT,
    CARD,
    COMBINED,
    ADVANCE_BOOK,
    CREDIT_BALANCE,
  ]).isRequired,
};

export default function PaymentTypeAvatar({ paymentType, ...props }) {
  return (
    <Avatar
      {...props}
      src={icons[paymentType]}
      style={{
        backgroundColor: "transparent",
      }}
    />
  );
}
