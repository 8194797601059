import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { ListSubheader, CircularProgress } from "@material-ui/core";
import TimeSlotItem from "./TimeSlotItem";

const enhancer = compose(
  useSheet({
    progress: {
      display: "flex",
      paddingTop: "24px",
      paddingBottom: "12px",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      display: "flex",
      flex: "1 1 0%",
      "& > div": { flex: "1 1 0%", overflowY: "auto" },
    },
  }),
);

TimeSlotList.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  timeSlotsFetching: PropTypes.bool.isRequired,
  timeSlots: PropTypes.instanceOf(List).isRequired,
};

function TimeSlotList(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      {props.title && <ListSubheader>{props.title}</ListSubheader>}

      {props.timeSlotsFetching ? (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.timeSlots.map(item => (
            <TimeSlotItem
              key={item.get("id")}
              item={item}
              date={props.date}
              onClick={props.onChange}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default enhancer(TimeSlotList);
