import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CUSTOMER_TIMESLOT_LIST_URL = `${API_V2_ROOT_URL}/customer/timeslots`;
const CUSTOMER_UPDATE_ORDER_PICKUP_TIMESLOT_URL = `${
  API_V2_ROOT_URL
}/customer/order/%s/timeslot/pickup`;
const CUSTOMER_UPDATE_ORDER_DELIVERY_TIMESLOT_URL = `${
  API_V2_ROOT_URL
}/customer/order/%s/timeslot/drop_off`;

export const getTimeSlots = (request: DataListFilter) =>
  api.getStream(CUSTOMER_TIMESLOT_LIST_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const updateOrderPickupTimeslot = (orderNumber, body) =>
  api.put(sprintf(CUSTOMER_UPDATE_ORDER_PICKUP_TIMESLOT_URL, orderNumber), {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const updateOrderDropOffTimeslot = (orderNumber, body) =>
  api.put(sprintf(CUSTOMER_UPDATE_ORDER_DELIVERY_TIMESLOT_URL, orderNumber), {
    body,
    headers: {
      "x-app-type": "customer-web",
    },
  });
