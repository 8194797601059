import sprintf from "sprintf";
import { Map } from "immutable";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CUSTOMER_PAYMENT_METHODS_URL = `${API_ROOT_URL}/customer/payment_methods`;
const CUSTOMER_ORDER_INTERNATIONAL_INVOICE = `${
  API_ROOT_URL
}/customer/order/international/%s/invoice`;
const CUSTOMER_ORDER_AWB_URL = `${API_ROOT_URL}/customer/order/%s/airwaybill`;
const CUSTOMER_MOST_USED_ADDRESSES_URL = `${
  API_ROOT_URL
}/customer/most_used_addresses`;

export const getPaymentMethods = (request: Map) =>
  api.postStream(CUSTOMER_PAYMENT_METHODS_URL, {
    body: request.toJS(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getMostUsedAddresses = () =>
  api.getStream(CUSTOMER_MOST_USED_ADDRESSES_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const sendInternationalInvoiceEmail = (orderId, emails) =>
  api.get(sprintf(CUSTOMER_ORDER_INTERNATIONAL_INVOICE, orderId), {
    params: { emails },
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const sendOrderAWBEmail = (orderId, emails) =>
  api.get(sprintf(CUSTOMER_ORDER_AWB_URL, orderId), {
    params: { emails },
    headers: {
      "x-app-type": "customer-web",
    },
  });
