import React from "react";
import PropTypes from "prop-types";
import Text, { TEXT_TYPE } from "./Text";
import { formatDistance } from "../../helpers/FormatUtils";

Distance.propTypes = {
  className: PropTypes.string,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  type: TEXT_TYPE,
  fallbackValue: PropTypes.node,
  fallbackType: TEXT_TYPE,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default function Distance({ distance, fallbackValue, ...props }) {
  return <Text {...props}>{formatDistance(distance, fallbackValue)}</Text>;
}
