import { Observable } from "rxjs";
import React from "react";
import { parse, format, startOfDay, differenceInSeconds } from "date-fns";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { isEqualData } from "../../helpers/DataUtils";

const setTimeToDate = (time, date) =>
  parse(`${format(date, "YYYY-MM-DD")} ${time}`);

const durationToNow = fp.flow(
  date => differenceInSeconds(date, new Date()),
  seconds => new Date(0, 0, 0, 0, 0, seconds),
  date => format(date, "H[h]:m[m]:s[s]"),
);

const enhancer = compose(
  mapPropsStream(propsStream => {
    const counterStream = propsStream
      .map(
        fp.flow(fp.pick(["time", "date"])),
        fp.update("time", fp.trim),
        fp.update("date", startOfDay),
      )
      .distinctUntilChanged(isEqualData)
      .map(props => setTimeToDate(props.time, props.date))
      .combineLatest(Observable.interval(1000).startWith(null), durationToNow);

    return propsStream
      .combineLatest(counterStream, (props, distance) => ({
        ...props,
        distance,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

CountdownTime.propTypes = {
  title: PropTypes.string,
  distance: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};

function CountdownTime(props) {
  return (
    <span>
      {props.title} {props.distance}
    </span>
  );
}

export default enhancer(CountdownTime);
