import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import CountdownTime from "./CountdownTime";
import TimeSlotAvatar from "./TimeSlotAvatar";
import Text, { MUTED, PRIMARY } from "../ui-core/Text";
import { formatTimeSlot } from "../../helpers/TimeSlotHelper";

const enhancer = compose(
  useSheet({
    container: {
      display: "flex",
      userSelect: "none",
      padding: "8px 12px",
      flexDirection: "row",
      backgroundColor: "#fff",
      "&:hover": { backgroundColor: "#f7f7f7" },
      transition: `all 250ms ease`,
    },

    containerActive: { cursor: "pointer" },
    containerInactive: { cursor: "not-allowed" },

    content: { flex: 1, overflow: "hidden" },

    title: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 8px",
    },
    titleText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    titleBadge: {
      top: 0,
      right: 0,
      color: "#fff",
      fontSize: "14px",
      fontWeight: 500,
      padding: "0 12px",
      borderRadius: "2px",
    },

    badgeActive: { backgroundColor: "#38a938" },
    badgeInactive: { backgroundColor: "#908d8d" },

    subtitle: {
      padding: "0 8px",
      fontSize: "13px",
      lineHeight: 1.5,
      fontWeight: 500,
    },
  }),
);

TimeSlotItem.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

function TimeSlotItem(props) {
  const { classes } = props;
  const available = props.item.get("available");

  return (
    <div
      role="button"
      tabIndex="-1"
      className={cx(
        classes.container,
        available ? classes.containerActive : classes.containerInactive,
      )}
      onKeyPress={() => {
        if (available) {
          props.onClick(props.item);
        }
      }}
      onClick={() => {
        if (available) {
          props.onClick(props.item);
        }
      }}
    >
      <TimeSlotAvatar name={props.item.get("name")} />

      <div className={classes.content}>
        <div className={classes.title}>
          <Text type={PRIMARY} className={classes.titleText}>
            {props.item.get("name")}
          </Text>

          <span
            className={cx(
              classes.titleBadge,
              available ? classes.badgeActive : classes.badgeInactive,
            )}
          >
            {available ? "Available" : "Unavailable"}
          </span>
        </div>

        <Text type={MUTED} element="div" className={classes.subtitle}>
          {formatTimeSlot(props.item)}
        </Text>

        <Text type={MUTED} element="div" className={classes.subtitle}>
          <CountdownTime
            date={props.date}
            time={props.item.get("custoff_time")}
            title={available ? "Availability ends in:" : "Available in:"}
          />
        </Text>
      </div>
    </div>
  );
}

export default enhancer(TimeSlotItem);
